/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  group_ids: number[]
  [k: string]: unknown
}

export interface OutputSchema {
  results?: Result[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Result {
  group_id?: number
  circles?: Circle[]
  [k: string]: unknown
}

export function isResult(v: unknown): v is Result {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getTelegramGroupCircles#result'
  )
}

export function validateResult(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.getTelegramGroupCircles#result',
    v,
  )
}

export interface Circle {
  /** circle_id */
  circle_id?: string
  circle_name?: string
  circle_type?: number
  creator?: string
  can_manage?: boolean
  description?: string
  is_public?: boolean
  /** The circle photo. */
  photo?: string
  /** The circle memberCount. */
  member_count?: number
  /** Whether in circle. */
  in_circle?: boolean
  /** The caller's status in circle. */
  status?: string
  [k: string]: unknown
}

export function isCircle(v: unknown): v is Circle {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getTelegramGroupCircles#circle'
  )
}

export function validateCircle(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.getTelegramGroupCircles#circle',
    v,
  )
}
