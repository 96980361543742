/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  walletAddress: string
  signature?: string
  message?: string
  limit?: number
  offset?: number
}

export type InputSchema = undefined

export interface OutputSchema {
  statisticsInfos: StatisticsInfo[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface StatisticsInfo {
  date: string
  registerTotal?: number
  registerNewTotal?: number
  registerNewTg?: number
  registerNewWeb?: number
  registerNewIos?: number
  registerNewAndroid?: number
  dayTwoRetention?: string
  daySevenRetention?: string
  dau?: number
  dailyPostSenders?: number
  exploreSenders?: number
  circleSenders?: number
  dailyPosts?: number
  explorePosts?: number
  circlePosts?: number
  [k: string]: unknown
}

export function isStatisticsInfo(v: unknown): v is StatisticsInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.admin.getActorStatistics#statisticsInfo'
  )
}

export function validateStatisticsInfo(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.admin.getActorStatistics#statisticsInfo',
    v,
  )
}
